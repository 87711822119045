export enum MixpanelEvent {
  HomeScreenView = 'Homepage View',
  DrugView = 'View Monograph',
  SearchResultsLoad = 'Search',
  DrugHandoutView = 'View Medication Guide',
  ClinicalHandoutView = 'View Patient Guide',
  AppendixView = 'View Appendix',
  AlgorithmView = 'View Algorithm',
  UserDataEdited = 'Update Profile',
  UserDataAdded = 'Add Personal Info',
  NativeAppOpen = 'App Open',
  SubscriptionPageView = 'View Subscription Page',
  Subscribe = 'Subscribe',
  SignIn = 'Login',
  QuickLinkClicked = 'Click Quick Link',
  DICDrugAdded = 'Add Drug to Interaction',
  DrugInteractions = 'Drug Interactions',
  DICShowAllInteractions = 'Show All Interactions Clicked',
  MultipleHandoutsEmailed = 'Email All Guides',
  MultipleHandoutsPrinted = 'Print All Guides',
  MultipleHandoutsDownloaded = 'Download All Guides',
  HandoutEmailed = 'Email',
  HandoutPrinted = 'Print',
  HandoutDownloaded = 'Download Guide',
  FavoritesItemAdded = 'Add Favorite',
  NoteAdded = 'Add Note',
  NoteEdited = 'Update Note',
  NoteViewed = 'View Note',
  ToCLinkClicked = 'Click ToC',
  ShowAllNotesClicked = 'Click Show All Notes',
  DxTxView = 'View Clinical Brief',
  ReviewPlanDetails = 'Review Plan Details',
  CompleteYourProfile = 'Complete Your Profile',
  PaymentDetails = 'Payment Details',
  CreateAnAccount = 'Create An Account',
  UpgradeToPro = 'Upgrade to Pro',
  AddMoreUsers = 'Add More Users',
  StudentDataFormSuccess = 'Student Data Form Success',
  StudentDataFormFailure = 'Student Data Form Failure',
  StudentDocumentUpload = 'Student Document Upload',
  StudentDocumentsVerificationSuccess = 'Student Documents Verification Success',
  StudentDocumentsVerificationFailure = 'Student Documents Verification Failure',
  StudentDocumentsUploadLimitReached = 'Student Documents Upload Limit Reached',
  StudentSSOProcessStarted = 'Student SSO Process Started',
  StudentSSOProcessSuccess = 'Student SSO Process Success',
  StudentSSOProcessSkipped = 'Student SSO Process Skipped',
  StudentEmailNotificationRedirect = 'Student Email Notification Redirect',
  StudentEmailLoopProcessSuccess = 'Student Email Loop Process Success',
  StudentEmailLoopProcessSkipped = 'Student Email Loop Process Skipped',
  CSATComplete = 'Customer Satisfaction Survey Complete',
  CSATClose = 'Customer Satisfaction Survey Close',
  NPSComplete = 'Net Promoter Score Survey Complete',
  NPSClose = 'Net Promoter Score Survey Close',
  AccordionOpen = 'Open Accordion',
  AccordionClose = 'Close Accordion',
  AccordionOpenAll = 'Open All Accordions',
  AccordionCloseAll = 'Close All Accordions',
  ConsensusStatementView = 'View Consensus Statement',
  AlgorithmInteraction = 'Algorithm Interaction',
  FeatureButtonClicked = 'Click Features Button',
  ToolsButtonClicked = 'Click Tools Button',
  BackToTopClicked = 'Click Back to Top',
  SelectHantoutToShare = 'Select to Share',
  ShareHandouts = 'Share Selected',
}

export type MixpanelSearchType =
  | 'All Content'
  | 'Drug Monographs'
  | 'Pet Owner Guides'
  | 'Algorithms'
  | 'Clinical Briefs';

export type MixpanelSearchLocation =
  | 'Pet Owner Guides Page'
  | 'Drug Monographs Page'
  | 'Homepage'
  | 'Clinical Briefs Page'
  | 'Algorithms Page';

export type MixpanelFavoriteLocation =
  | 'Homepage'
  | 'List Page'
  | 'Single Item Page'
  | 'Search Results Page'
  | 'Recently Viewed'
  | 'Top Viewed';

export type MixpanelQuickLinkName =
  | 'Dosages'
  | 'Dosage Forms'
  | 'Signalment'
  | 'Presentation'
  | 'Diagnosis'
  | 'Treatment';

export type MixpanelQuickLinkLocation =
  | 'Top of Monograph'
  | 'List of Monographs'
  | 'Search Results Page'
  | 'List of Clinical Briefs'
  | 'Top of Clinical Brief'
  | 'Top Viewed'
  | 'Recently Viewed';

export type MixpanelQuickLinkLocationParams = {
  Location: MixpanelQuickLinkLocation;
  'Sub-Location'?: MixpanelContentSublocation;
};

export type MixpanelNotesLocation = 'Homepage' | 'Drug Page';

export type MixpanelContentBaseLocation =
  | 'My Favorites'
  | 'Search Results'
  | 'Notes'
  | 'Recently Viewed'
  | 'Top Viewed';
export type MixpanelDxTxLocation = MixpanelContentBaseLocation | 'Clinical Brief Landing Page';
export type MixpanelAppendixLocation = MixpanelContentBaseLocation | 'Drugs Monographs Page';
export type MixpanelDrugLocation =
  | MixpanelContentBaseLocation
  | 'Drugs Monographs Page'
  | 'Interaction Checker';
export type MixpanelAlgorithmLocation = MixpanelContentBaseLocation | 'Algorithms Page';
export type MixpanelHandoutsBaseLocation = MixpanelContentBaseLocation | 'Pet Owner Guides Page';
export type MixpanelShowAllNotesLocation = 'Monograph Page' | 'Clinical Briefs Page';

export type MixpanelDICLocation = 'Monograph Page' | 'Features Navigation Bar';

export type MixpanelContentSublocation = 'New' | 'Updated' | 'Favorites';

interface TOCParamsBase {
  'ToC Topic Clicked': string;
  'Content Type': 'Drug' | 'Dx&Tx' | 'Drug Handout' | 'Clinical Handout';
}
export interface DrugTOCParams extends TOCParamsBase {
  'Drug Title': string;
  'Drug ID': string;
  'Content Type': 'Drug';
}

export interface DxTxTOCParams extends TOCParamsBase {
  'Dx&Tx Title': string;
  'Dx&Tx ID': string;
  'Content Type': 'Dx&Tx';
}

export interface DrugHandoutTOCParams extends TOCParamsBase {
  'Drug Handout Title': string;
  'Drug Handout ID': string;
  'Content Type': 'Drug Handout';
}

export interface ClinicalHandoutTOCParams extends TOCParamsBase {
  'Clinical Handout Title': string;
  'Clinical Handout ID': string;
  'Content Type': 'Clinical Handout';
}

export type TOCClickedParams =
  | DrugTOCParams
  | DxTxTOCParams
  | DrugHandoutTOCParams
  | ClinicalHandoutTOCParams;

export type MixpanelClinicalHandoutLocation =
  | MixpanelHandoutsBaseLocation
  | 'Clinical Brief Search Tile'
  | 'Clinical Briefs Page';
export type MixpanelDrugHandoutLocation =
  | MixpanelHandoutsBaseLocation
  | 'Monograph Search Tile'
  | 'Monograph Page';

export type MixpanelDisplayMode = 'Light Mode' | 'Dark Mode';

export type MixpanelUpgradeToProLocation = 'Plan Type' | 'Plan Benefits';

export type MixpanelStudentVerificationStatus =
  | 'Success'
  | 'Failed'
  | 'Documents Upload'
  | 'Documents Verification Pending'
  | 'SSO'
  | 'SSO Pending'
  | 'Email Loop'
  | 'Expired'
  | 'Started';

export interface MixpanelStudentVerificationParams {
  'First Name': string;
  'Last Name': string;
  'Email Address': string;
  'Student Email Address': string;
  'Birth Date': string;
  Country: string;
  University: string;
  Role: 'Veterinary Student' | 'Pharmacy Student';
  'Net New Subscriber': boolean;
}

interface MultipleHandoutsActionParams {
  'VMG Titles'?: string[];
  'Patient Guide Titles'?: string[];
  'VMG ID'?: string[];
  'Patient Guide ID'?: string[];
  'Custom Note': boolean;
}

interface SingleHandoutActionParams {
  'VMG Title'?: string;
  'Patient Guide Title'?: string;
  'VMG ID'?: string;
  'Patient Guide ID'?: string;
  'Custom Note': boolean;
}

type ContentItemAccordionLocation = 'Dx&Tx' | 'Drug Monograph' | 'Patient Guide' | 'VMG';
export type MixpanelContentListAccordionLocation = `${
  | ContentItemAccordionLocation
  | 'Appendix'
  | 'Algorithms'} Index`;

interface ContentItemAccordionParamsBase {
  'Accordion Location': ContentItemAccordionLocation;
  'Accordion Name': string;
}

interface ContentListAccordionParams {
  'Accordion Name': string;
  'Accordion Location': MixpanelContentListAccordionLocation;
}

interface ContentListAllAccordionParams {
  'Accordion Location': MixpanelContentListAccordionLocation;
}

export type SingleContentAllAccordionsParams<T extends ContentItemAccordionParamsBase> = Exclude<
  T,
  'Accordion Name'
>;

interface DrugAccordionActionParams extends ContentItemAccordionParamsBase {
  'Accordion Location': 'Drug Monograph';
  'Drug ID': string;
  'Drug Name': string;
}

interface DxTxAccordionActionParams extends ContentItemAccordionParamsBase {
  'Accordion Location': 'Dx&Tx';
  'Dx&Tx ID': string;
  'Dx&Tx Name': string;
}

interface ClinicalHandoutAccordionActionParams extends ContentItemAccordionParamsBase {
  'Accordion Location': 'Patient Guide';
  'Patient Guide ID': string;
  'Patient Guide Name': string;
}

interface DrugHandoutAccordionActionParams extends ContentItemAccordionParamsBase {
  'Accordion Location': 'VMG';
  'VMG ID': string;
  'VMG Name': string;
}

export type MixpanelContentSingleAccordionActionParams =
  | DrugAccordionActionParams
  | DxTxAccordionActionParams
  | ClinicalHandoutAccordionActionParams
  | DrugHandoutAccordionActionParams
  | ContentListAccordionParams;

export type MixpanelContentAllAccordionsActionParams =
  | SingleContentAllAccordionsParams<DrugAccordionActionParams>
  | SingleContentAllAccordionsParams<DxTxAccordionActionParams>
  | SingleContentAllAccordionsParams<ClinicalHandoutAccordionActionParams>
  | SingleContentAllAccordionsParams<DrugHandoutAccordionActionParams>
  | ContentListAllAccordionParams;

export type MixpanelAlgorithmButton =
  | 'Open Full Screen'
  | 'Close Full Screen'
  | '+ sign'
  | '- sign'
  | 'Left Arrow'
  | 'Right Arrow';

export type MixpanelFeatureButton =
  | 'Dx & Tx'
  | 'Drugs'
  | 'Interaction Checker'
  | 'Algorithms'
  | 'Clinical Handouts'
  | 'Drug Handouts'
  | 'Appendix'
  | 'DDx';

export type MixpanelToolsButton = 'Selected Handouts' | 'Calculator' | 'Notes';

interface BackToTopInContentBase {
  Location: 'In Content';
}

interface MixpanelBackToTopClinicalHandout extends BackToTopInContentBase {
  'Clinical Handout ID': string;
  'Clinical Handout Name': string;
}

interface MixpanelBackToTopDrugHandout extends BackToTopInContentBase {
  'Drug Handout ID': string;
  'Drug Handout Name': string;
}

interface MixpanelBackToTopMonograph extends BackToTopInContentBase {
  'Monograph ID': string;
  'Monograph Name': string;
}

interface MixpanelBackToTopDxTx extends BackToTopInContentBase {
  'Dx & Tx ID': string;
  'Dx & Tx Name': string;
}

interface MixpanelBackToTopAppendix extends BackToTopInContentBase {
  'Appendix ID': string;
  'Appendix Name': string;
}

export interface MixpanelBackToTopInList {
  Location:
    | 'Clinical Handouts Index'
    | 'VMG Index'
    | 'Drug Monongraph Index'
    | 'Dx&Tx Index'
    | 'Algorithm Index'
    | 'Appendix Index'
    | 'DDx Index';
}

export type MixpanelBackToTopInContent =
  | MixpanelBackToTopClinicalHandout
  | MixpanelBackToTopDrugHandout
  | MixpanelBackToTopMonograph
  | MixpanelBackToTopDxTx
  | MixpanelBackToTopAppendix;

export type MixpanelBackToTopParams = MixpanelBackToTopInContent | MixpanelBackToTopInList;

export type MixpanelShareHandoutLocation =
  | 'Clinical Handouts Index'
  | 'VMG Index'
  | 'Homepage'
  | 'Search Results Page'
  | 'Clinical Handout Page'
  | 'VMG Page'
  | 'Drug Page'
  | 'Dx & Tx Page';

export type MixpanelShareHandoutSubLocation =
  | 'New'
  | 'Updated'
  | 'Top Viewed'
  | 'Recently Viewed'
  | 'Favorites';

export interface MixpanelSelectHandoutParams {
  location: MixpanelShareHandoutLocation;
  subLocation?: MixpanelShareHandoutSubLocation;
}

export interface MixpanelShareSelectedHandoutsParams {
  'Clinical Handout Names'?: string[];
  'Clinical Handout IDs'?: string[];
  'Drug Handout Names'?: string[];
  'Drug Handout IDs'?: string[];
}

export interface MixpanelNoteParams {
  'Drug Title'?: string;
  'Drug ID'?: string;
  'Note Location': string;
}

export type MixpanelEventParams = {
  [MixpanelEvent.HomeScreenView]: undefined;
  [MixpanelEvent.DrugView]: {
    'Drug Title': string;
    'Drug ID': string;
    'Search Term Before Click Through'?: string;
    'Display Mode': MixpanelDisplayMode;
    Location: MixpanelDrugLocation;
    'Sub-Location'?: MixpanelContentSublocation;
  };
  [MixpanelEvent.SearchResultsLoad]: {
    'Search Type': MixpanelSearchType;
    'Search Term': string;
    'Search Location': MixpanelSearchLocation;
    'Number of Results Returned': number;
  };
  [MixpanelEvent.DrugHandoutView]: {
    'VMG Title': string;
    Location: MixpanelDrugHandoutLocation;
    'Sub-Location'?: MixpanelContentSublocation;
    'VMG ID': string;
    'Search Term Before Click Through'?: string;
  };
  [MixpanelEvent.ClinicalHandoutView]: {
    'Patient Guide Title': string;
    'Patient Guide ID': string;
    'Search Term Before Click Through'?: string;
    Location: MixpanelClinicalHandoutLocation;
    'Sub-Location'?: MixpanelContentSublocation;
  };
  [MixpanelEvent.UserDataEdited]: Record<string, any>;
  [MixpanelEvent.UserDataAdded]: Record<string, any>;
  [MixpanelEvent.NativeAppOpen]: undefined;
  [MixpanelEvent.SubscriptionPageView]: undefined;
  [MixpanelEvent.Subscribe]: undefined;
  [MixpanelEvent.SignIn]: undefined;
  [MixpanelEvent.QuickLinkClicked]: {
    'Link Name': MixpanelQuickLinkName;
    'Drug Title': string;
    'Drug ID': string;
  } & MixpanelQuickLinkLocationParams;
  [MixpanelEvent.DICDrugAdded]: {
    'Drug Name': string;
    'Number of Drugs Added': number;
    Location: MixpanelDICLocation;
  };
  [MixpanelEvent.DrugInteractions]: {
    'Drugs Names': string[];
    Location: MixpanelDICLocation;
  };
  [MixpanelEvent.DICShowAllInteractions]: {
    'Drug Name': string;
    Location: MixpanelDICLocation;
  };
  [MixpanelEvent.MultipleHandoutsEmailed]: {
    'VMG Titles'?: string[];
    'Clinical Guide Titles'?: string[];
    'VMG ID'?: string[];
    'Clinical Guide ID'?: string[];
    'Email Subject': string;
  };
  [MixpanelEvent.MultipleHandoutsPrinted]: MultipleHandoutsActionParams;
  [MixpanelEvent.MultipleHandoutsDownloaded]: MultipleHandoutsActionParams;
  [MixpanelEvent.HandoutEmailed]: {
    'VMG Title'?: string;
    'Clinical Guide Title'?: string;
    'VMG ID'?: string;
    'Clinical Guide ID'?: string;
    'Email Subject': string;
  };
  [MixpanelEvent.HandoutPrinted]: SingleHandoutActionParams;
  [MixpanelEvent.HandoutDownloaded]: SingleHandoutActionParams;
  [MixpanelEvent.FavoritesItemAdded]: {
    'Drug Title': string;
    'Drug ID': string;
    Location: MixpanelFavoriteLocation;
  };
  [MixpanelEvent.NoteAdded]: MixpanelNoteParams;
  [MixpanelEvent.NoteEdited]: MixpanelNoteParams;
  [MixpanelEvent.NoteViewed]: MixpanelNoteParams;
  [MixpanelEvent.ToCLinkClicked]: TOCClickedParams;
  [MixpanelEvent.ShowAllNotesClicked]: {
    'Drug Title': string;
    'Drug ID': string;
    Location: MixpanelShowAllNotesLocation;
  };
  [MixpanelEvent.DxTxView]: {
    'Clinical Brief Title': string;
    'Clinical Brief ID': string;
    'Search Term Before Click Through'?: string;
    Location: MixpanelDxTxLocation;
    'Sub-Location'?: MixpanelContentSublocation;
  };
  [MixpanelEvent.ReviewPlanDetails]: {
    'Your Role/Occupation Plan Details': string;
    "Your Plumb's Plan Details": string;
    "Your Plan's Number of Users Plan Details": number;
  };
  [MixpanelEvent.CompleteYourProfile]: undefined;
  [MixpanelEvent.PaymentDetails]: undefined;
  [MixpanelEvent.AppendixView]: {
    'Appendix Title': string;
    'Appendix ID': string;
    'Search Term Before Click Through'?: string;
    Location: MixpanelAppendixLocation;
    'Sub-Location'?: MixpanelContentSublocation;
  };
  [MixpanelEvent.AlgorithmView]: {
    'Algorithm Title': string;
    'Algorithm ID': string;
    'Search Term Before Click Through'?: string;
    Location: MixpanelAlgorithmLocation;
    'Sub-Location'?: MixpanelContentSublocation;
  };
  [MixpanelEvent.CreateAnAccount]: {
    Email: string;
    'Account Created Date': string;
  };
  [MixpanelEvent.AddMoreUsers]: {
    Location: string;
  };
  [MixpanelEvent.UpgradeToPro]: {
    Location: MixpanelUpgradeToProLocation;
  };
  [MixpanelEvent.StudentDataFormSuccess]: MixpanelStudentVerificationParams;
  [MixpanelEvent.StudentDataFormFailure]: MixpanelStudentVerificationParams & {
    'Next Student Verification Step'?: 'Documents Upload' | 'SSO';
  };
  [MixpanelEvent.StudentDocumentUpload]: MixpanelStudentVerificationParams;
  [MixpanelEvent.StudentDocumentsVerificationSuccess]: MixpanelStudentVerificationParams;
  [MixpanelEvent.StudentDocumentsVerificationFailure]: MixpanelStudentVerificationParams;
  [MixpanelEvent.StudentDocumentsUploadLimitReached]: MixpanelStudentVerificationParams;
  [MixpanelEvent.StudentSSOProcessStarted]: MixpanelStudentVerificationParams;
  [MixpanelEvent.StudentSSOProcessSuccess]: MixpanelStudentVerificationParams;
  [MixpanelEvent.StudentSSOProcessSkipped]: MixpanelStudentVerificationParams;
  [MixpanelEvent.StudentEmailLoopProcessSuccess]: MixpanelStudentVerificationParams;
  [MixpanelEvent.StudentEmailLoopProcessSkipped]: MixpanelStudentVerificationParams;
  [MixpanelEvent.StudentEmailNotificationRedirect]: MixpanelStudentVerificationParams & {
    'Verification Status'?: MixpanelStudentVerificationStatus;
  };
  [MixpanelEvent.CSATComplete]: {
    'CSAT Rate': number;
    'CSAT Feedback'?: string;
  };
  [MixpanelEvent.CSATClose]: undefined;
  [MixpanelEvent.NPSComplete]: {
    'NPS Rate': number | null;
    'NPS Feedback'?: string;
  };
  [MixpanelEvent.NPSClose]: undefined;
  [MixpanelEvent.AccordionOpen]: MixpanelContentSingleAccordionActionParams;
  [MixpanelEvent.AccordionClose]: MixpanelContentSingleAccordionActionParams;
  [MixpanelEvent.AccordionOpenAll]: MixpanelContentAllAccordionsActionParams;
  [MixpanelEvent.AccordionCloseAll]: MixpanelContentAllAccordionsActionParams;
  [MixpanelEvent.ConsensusStatementView]: {
    'Content Name': string;
    'Content ID': string;
  };
  [MixpanelEvent.AlgorithmInteraction]: {
    Button: MixpanelAlgorithmButton;
  };
  [MixpanelEvent.FeatureButtonClicked]: {
    'Feature Name': MixpanelFeatureButton;
  };
  [MixpanelEvent.ToolsButtonClicked]: {
    'Tool Name': MixpanelToolsButton;
  };
  [MixpanelEvent.BackToTopClicked]: MixpanelBackToTopParams;
  [MixpanelEvent.SelectHantoutToShare]: {
    Location: MixpanelShareHandoutLocation;
    'Sub Location'?: MixpanelShareHandoutSubLocation;
  };
  [MixpanelEvent.ShareHandouts]: MixpanelShareSelectedHandoutsParams;
};

export interface IMixpanelService {
  initialize(): Promise<void>;
  track<T extends MixpanelEvent>(eventName: T, params?: MixpanelEventParams[T]): void;
  identify(id: string): void;
  setUserProperties(data: Record<string, any>): void;
  clearUser(): void;
  setSuperProperties(data: Record<string, string>): void;
  setGroup(groupKey: string, groupId: string): void;
  unsetGroup(groupKey: string): void;
}
