import React from 'react';

import { ContentItemScreen } from 'src/components/ContentItemScreen/ContentItemScreen';
import { useContentNotes } from 'src/features/notes';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { ScreenProps } from 'src/navigation/types';

import { useDDxItem } from '../hooks/queries';

export interface Props extends ScreenProps<Route.DDxItem> {}

export const DDxItemScreen: React.FC<Props> = ({ route }) => {
  const { id, section, subsection } = route.params;
  const { data, error, fetchStatus } = useDDxItem(id);

  const notes = useContentNotes(id);

  return (
    <>
      <ContentItemScreen
        title={i18n.t('contentTypeLabel:ddx')}
        titleInUppercase={false}
        item={data}
        notes={notes}
        error={error}
        sectionToScrollTo={section}
        subsectionToScrollTo={subsection}
        sectionOpenByDefault="differentials"
        fetchStatus={fetchStatus}
      />
    </>
  );
};
